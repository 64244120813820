import Vue from "vue";
import Router from "vue-router";
import { hasRole, hasMultiplePermission } from "./store/modules/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "dashboards/classic",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Auth Layout",
          path: "auth",
          component: () => import("@/views/login/Index"),
          children: [
            {
              name: "Login",
              path: "login",
              component: () => import("@/views/login/components/Login"),
            },
            {
              name: "ForgotPassword",
              path: "forgot-password",
              component: () =>
                import("@/views/login/components/ForgotPassword"),
            },
            {
              name: "ChangePassword",
              path: "change-password",
              component: () =>
                import("@/views/login/components/ChangePassword"),
              beforeEnter: (to, from, next) => {
                if (to.params.email) {
                  next();
                } else {
                  next("/auth/login");
                }
              },
            },
          ],
        },
      ],
    },
    // {
    //   path: "/charts",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "ApexCharts",
    //       path: "apexcharts",
    //       component: () => import("@/views/charts/apexcharts/ApexCharts"),
    //     },
    //   ],
    // },
    // {
    //   path: "/extra-components",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "DragnDrop",
    //       path: "DragnDrop",
    //       component: () =>
    //         import("@/views/extra-components/drag-n-drop/DragnDrop"),
    //     },
    //   ],
    // },
    // {
    //   path: "/tables",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "TableSimple",
    //       path: "TableSimple",
    //       component: () => import("@/views/tables/table-simple/TableSimple"),
    //     },
    //     {
    //       name: "TableDataIterators",
    //       path: "TableDataIterators",
    //       component: () =>
    //         import("@/views/tables/table-data-iterators/TableDataIterators"),
    //     },
    //     {
    //       name: "TableDatatable",
    //       path: "TableDatatable",
    //       component: () =>
    //         import("@/views/tables/table-data-table/TableDatatable"),
    //     },
    //   ],
    // },
    // {
    //   path: "/icons",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "MaterialIcons",
    //       path: "MaterialIcons",
    //       component: () => import("@/views/icons/MaterialIcons"),
    //     },
    //     {
    //       name: "ThemifyIcons",
    //       path: "ThemifyIcons",
    //       component: () => import("@/views/icons/ThemifyIcons"),
    //     },
    //     {
    //       name: "FontAwesomeIcons",
    //       path: "FontAwesomeIcons",
    //       component: () => import("@/views/icons/FontAwesomeIcons"),
    //     },
    //     {
    //       name: "SimpleLineIcons",
    //       path: "SimpleLineIcons",
    //       component: () => import("@/views/icons/SimpleLineIcons"),
    //     },
    //   ],
    // },
    // {
    //   path: "/form-layouts",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "FLFormAction",
    //       path: "FLFormAction",
    //       component: () =>
    //         import("@/views/form-layouts/form-action/FLFormAction"),
    //     },
    //     {
    //       name: "FLFormBasic",
    //       path: "FLFormBasic",
    //       component: () =>
    //         import("@/views/form-layouts/form-basic/FLFormBasic"),
    //     },
    //     {
    //       name: "FormWizard",
    //       path: "FormWizard",
    //       component: () =>
    //         import("@/views/form-layouts/form-wizard/FormWizard"),
    //     },
    //     {
    //       name: "FLFormHorizontal",
    //       path: "FLFormHorizontal",
    //       component: () =>
    //         import("@/views/form-layouts/form-horizontal/FLFormHorizontal"),
    //     },
    //     {
    //       name: "FormValidation",
    //       path: "FormValidation",
    //       component: () =>
    //         import("@/views/form-layouts/form-validation/FormValidation"),
    //     },
    //   ],
    // },
    // {
    //   path: "/form-elements",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "FEAutocompletes",
    //       path: "FEautocompletes",
    //       component: () => import("@/views/form-elements/FEAutocompletes"),
    //     },
    //     {
    //       name: "FECombobox",
    //       path: "FECombobox",
    //       component: () => import("@/views/form-elements/FECombobox"),
    //     },
    //     {
    //       name: "FEFileInputs",
    //       path: "FEFileInputs",
    //       component: () => import("@/views/form-elements/FEFileInputs"),
    //     },
    //     {
    //       name: "FEInputs",
    //       path: "FEInputs",
    //       component: () => import("@/views/form-elements/FEInputs"),
    //     },
    //     {
    //       name: "FEOverflowButtons",
    //       path: "FEOverflowButtons",
    //       component: () => import("@/views/form-elements/FEOverflowButtons"),
    //     },
    //     {
    //       name: "FESelectionControls",
    //       path: "FESelectionControls",
    //       component: () => import("@/views/form-elements/FESelectionControls"),
    //     },
    //     {
    //       name: "FESelects",
    //       path: "FESelects",
    //       component: () => import("@/views/form-elements/FESelects"),
    //     },
    //     {
    //       name: "FESliders",
    //       path: "FESliders",
    //       component: () => import("@/views/form-elements/FESliders"),
    //     },
    //     {
    //       name: "FETextareas",
    //       path: "FETextareas",
    //       component: () => import("@/views/form-elements/FETextareas"),
    //     },
    //     {
    //       name: "FETextfields",
    //       path: "FETextfields",
    //       component: () => import("@/views/form-elements/FETextfields"),
    //     },
    //   ],
    // },
    // {
    //   path: "/apps",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     // Application
    //     {
    //       name: "Apps",
    //       path: "chat",
    //       component: () => import("@/views/apps/chat/Chat"),
    //     },
    //     {
    //       name: "FullCalendar",
    //       path: "fullcalendar",
    //       component: () => import("@/views/apps/fullcalendar/FullCalendar"),
    //     },
    //     {
    //       name: "Ecommerce-shop",
    //       path: "shop",
    //       component: () => import("@/views/apps/ecommerce/Listing"),
    //     },
    //     {
    //       name: "Contact",
    //       path: "contact",
    //       component: () => import("@/views/apps/contact/Contact"),
    //     },
    //     {
    //       name: "ContactList",
    //       path: "contact-list",
    //       component: () => import("@/views/apps/contact-list/ContactList"),
    //     },
    //     {
    //       name: "ContactGrid",
    //       path: "contact-grid",
    //       component: () => import("@/views/apps/contact-grid/ContactGrid"),
    //     },
    //     {
    //       name: "Email",
    //       path: "email/inbox",
    //       component: () => import("@/views/apps/email/Email"),
    //       children: [
    //         {
    //           path: "/apps/email/:mailType",
    //           name: "MailIndex",
    //           component: () => import("@/views/apps/email/EmailList.vue"),
    //         },
    //         {
    //           path: "/apps/email/:mailType/0/:uuid",
    //           name: "MailDetail1",
    //           component: () => import(`@/views/apps/email/EmailDetail.vue`),
    //         },
    //         {
    //           path: "/apps/email/0/:mailTag",
    //           name: "MailTag",
    //           component: () => import("@/views/apps/email/EmailList.vue"),
    //         },
    //         {
    //           path: "/apps/email/0/:mailTag/0/:uuid",
    //           name: "MailDetail",
    //           component: () => import(`@/views/apps/email/EmailDetail.vue`),
    //         },
    //       ],
    //     },
    //     {
    //       name: "Todo",
    //       path: "todo",
    //       component: () => import("@/views/apps/todo/Todo"),
    //     },
    //   ],
    // },
    {
      path: "/dashboards",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        // {
        //   name: "Analytical",
        //   path: "analytical",
        //   component: () => import("@/views/dashboards/analytical/Analytical"),
        // },
        {
          name: "Classic",
          path: "classic",
          component: () => import("@/views/dashboards/index"),
        },
        // {
        //   name: "Demographical",
        //   path: "demographical",
        //   component: () =>
        //     import("@/views/dashboards/demographical/Demographical"),
        // },
        // {
        //   name: "Minimal",
        //   path: "minimal",
        //   component: () => import("@/views/dashboards/minimal/Minimal"),
        // },
        // {
        //   name: "Overview",
        //   path: "overview",
        //   component: () => import("@/views/dashboards/overview/Overview"),
        // },
        // {
        //   name: "Ecommerce",
        //   path: "ecommerce",
        //   component: () => import("@/views/dashboards/ecommerce/Ecommerce"),
        // },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "/userProfile",
          name: "User Profile",
          component: () => import("@/views/userProfile/userProfile.page.vue"),
        },
        {
          path: "/changePassword",
          name: "Change Password",
          component: () =>
            import("@/views/change-password/change-password.vue"),
        },
      ],
    },
    // {
    //   path: "/components",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     // Components
    //     {
    //       name: "Alerts",
    //       path: "alerts",
    //       component: () => import("@/views/components/Alerts"),
    //     },
    //     {
    //       name: "Avatar",
    //       path: "avatar",
    //       component: () => import("@/views/components/Avatar"),
    //     },
    //     {
    //       name: "Badge",
    //       path: "badge",
    //       component: () => import("@/views/components/Badge"),
    //     },
    //     {
    //       name: "Banner",
    //       path: "banner",
    //       component: () => import("@/views/components/Banner"),
    //     },
    //     {
    //       name: "Appbar",
    //       path: "appbar",
    //       component: () => import("@/views/components/Appbar"),
    //     },
    //     {
    //       name: "Toolbar",
    //       path: "toolbar",
    //       component: () => import("@/views/components/Toolbar"),
    //     },
    //     {
    //       name: "Systembar",
    //       path: "systembar",
    //       component: () => import("@/views/components/Systembar"),
    //     },
    //     {
    //       name: "BottomNavigation",
    //       path: "bottomnavigation",
    //       component: () => import("@/views/components/BottomNavigation"),
    //     },
    //     {
    //       name: "Bottomsheet",
    //       path: "bottomsheet",
    //       component: () => import("@/views/components/Bottomsheet"),
    //     },
    //     {
    //       name: "Breadcrumb",
    //       path: "breadcrumb",
    //       component: () => import("@/views/components/Breadcrumb"),
    //     },
    //     {
    //       name: "Buttons",
    //       path: "buttons",
    //       component: () => import("@/views/components/Buttons"),
    //     },
    //     {
    //       name: "Fab",
    //       path: "fab",
    //       component: () => import("@/views/components/Fab"),
    //     },
    //     {
    //       name: "Cards",
    //       path: "cards",
    //       component: () => import("@/views/components/Cards"),
    //     },
    //     {
    //       name: "Carousel",
    //       path: "carousel",
    //       component: () => import("@/views/components/Carousel"),
    //     },
    //     {
    //       name: "Chips",
    //       path: "chips",
    //       component: () => import("@/views/components/Chips"),
    //     },
    //     {
    //       name: "Dialogs",
    //       path: "dialogs",
    //       component: () => import("@/views/components/Dialogs"),
    //     },
    //     {
    //       name: "Dividers",
    //       path: "dividers",
    //       component: () => import("@/views/components/Dividers"),
    //     },
    //     {
    //       name: "Expansion Panel",
    //       path: "expansionpanel",
    //       component: () => import("@/views/components/ExpansionPanel"),
    //     },
    //     {
    //       name: "Footer",
    //       path: "footer",
    //       component: () => import("@/views/components/Footer"),
    //     },
    //     {
    //       name: "Sheet",
    //       path: "sheet",
    //       component: () => import("@/views/components/Sheet"),
    //     },
    //     {
    //       name: "Overflow Buttons",
    //       path: "overflow-buttons",
    //       component: () => import("@/views/components/OverflowButtons"),
    //     },
    //     {
    //       name: "Grids",
    //       path: "grids",
    //       component: () => import("@/views/components/Grids"),
    //     },
    //     {
    //       name: "Button Groups",
    //       path: "button-group",
    //       component: () => import("@/views/components/ButtonGroups"),
    //     },
    //     {
    //       name: "Chip Groups",
    //       path: "chip-group",
    //       component: () => import("@/views/components/ChipGroups"),
    //     },
    //     {
    //       name: "Item Groups",
    //       path: "item-group",
    //       component: () => import("@/views/components/ItemGroups"),
    //     },
    //     {
    //       name: "List Item Groups",
    //       path: "listitem-group",
    //       component: () => import("@/views/components/ListItemGroups"),
    //     },
    //     {
    //       name: "Slide Groups",
    //       path: "slide-group",
    //       component: () => import("@/views/components/SlideGroups"),
    //     },
    //     {
    //       name: "Windows",
    //       path: "windows",
    //       component: () => import("@/views/components/Windows"),
    //     },
    //     {
    //       name: "Hover",
    //       path: "hover",
    //       component: () => import("@/views/components/Hover"),
    //     },
    //     {
    //       name: "Icons",
    //       path: "icons",
    //       component: () => import("@/views/components/Icons"),
    //     },
    //     {
    //       name: "Images",
    //       path: "images",
    //       component: () => import("@/views/components/Images"),
    //     },
    //     {
    //       name: "Lists",
    //       path: "lists",
    //       component: () => import("@/views/components/Lists"),
    //     },
    //     {
    //       name: "Menus",
    //       path: "menus",
    //       component: () => import("@/views/components/Menus"),
    //     },
    //     {
    //       name: "Navigation Drawers",
    //       path: "navigation-drawers",
    //       component: () => import("@/views/components/NavigationDrawers"),
    //     },
    //     {
    //       name: "Overlay",
    //       path: "overlay",
    //       component: () => import("@/views/components/Overlay"),
    //     },
    //     {
    //       name: "Pagination",
    //       path: "pagination",
    //       component: () => import("@/views/components/Pagination"),
    //     },
    //     {
    //       name: "Parallex",
    //       path: "parallex",
    //       component: () => import("@/views/components/Parallex"),
    //     },
    //     {
    //       name: "Color Pickers",
    //       path: "color-pickers",
    //       component: () => import("@/views/components/ColorPickers"),
    //     },
    //     {
    //       name: "Date Pickers",
    //       path: "date-pickers",
    //       component: () => import("@/views/components/DatePickers"),
    //     },
    //     {
    //       name: "Time Pickers",
    //       path: "time-pickers",
    //       component: () => import("@/views/components/TimePickers"),
    //     },
    //     {
    //       name: "Progress Circular",
    //       path: "progress-circular",
    //       component: () => import("@/views/components/ProgressCircular"),
    //     },
    //     {
    //       name: "Progress Linear",
    //       path: "progress-linear",
    //       component: () => import("@/views/components/ProgressLinear"),
    //     },
    //     {
    //       name: "Rating",
    //       path: "rating",
    //       component: () => import("@/views/components/Rating"),
    //     },
    //     {
    //       name: "SkeletonLoaders",
    //       path: "skeleton-loaders",
    //       component: () => import("@/views/components/SkeletonLoaders"),
    //     },
    //     {
    //       name: "Snackbar",
    //       path: "snackbar",
    //       component: () => import("@/views/components/Snackbar"),
    //     },
    //     {
    //       name: "Sparkline",
    //       path: "sparkline",
    //       component: () => import("@/views/components/Sparkline"),
    //     },
    //     {
    //       name: "Steppers",
    //       path: "steppers",
    //       component: () => import("@/views/components/Steppers"),
    //     },
    //     {
    //       name: "Subheaders",
    //       path: "subheaders",
    //       component: () => import("@/views/components/Subheaders"),
    //     },
    //     {
    //       name: "Tabs",
    //       path: "tabs",
    //       component: () => import("@/views/components/Tabs"),
    //     },
    //     {
    //       name: "Timeline",
    //       path: "timeline",
    //       component: () => import("@/views/components/Timeline"),
    //     },
    //     {
    //       name: "Tooltips",
    //       path: "tooltips",
    //       component: () => import("@/views/components/Tooltips"),
    //     },
    //     {
    //       name: "Treeview",
    //       path: "treeview",
    //       component: () => import("@/views/components/Treeview"),
    //     },
    //   ],
    // },
    // {
    //   path: "/style-animation",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "Colors",
    //       path: "colors",
    //       component: () => import("@/views/style-animation/Colors"),
    //     },
    //     {
    //       name: "Content",
    //       path: "content",
    //       component: () => import("@/views/style-animation/Content"),
    //     },
    //     {
    //       name: "Display",
    //       path: "display",
    //       component: () => import("@/views/style-animation/Display"),
    //     },
    //     {
    //       name: "Elevation",
    //       path: "elevation",
    //       component: () => import("@/views/style-animation/Elevation"),
    //     },
    //     {
    //       name: "Flex",
    //       path: "flex",
    //       component: () => import("@/views/style-animation/Flex"),
    //     },
    //     {
    //       name: "Float",
    //       path: "float",
    //       component: () => import("@/views/style-animation/Float"),
    //     },
    //     {
    //       name: "Spacing",
    //       path: "spacing",
    //       component: () => import("@/views/style-animation/Spacing"),
    //     },
    //     {
    //       name: "Text",
    //       path: "text",
    //       component: () => import("@/views/style-animation/Text"),
    //     },
    //     {
    //       name: "Typography",
    //       path: "typography",
    //       component: () => import("@/views/style-animation/Typography"),
    //     },
    //     {
    //       name: "Transitions",
    //       path: "transitions",
    //       component: () => import("@/views/style-animation/Transitions"),
    //     },
    //     {
    //       name: "ProgrammaticScrolling",
    //       path: "scroll",
    //       component: () => import("@/views/style-animation/Scroll"),
    //     },
    //   ],
    // },
    {
      path: "/customer",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "customerlist",
          name: "Customerlist",
          component: () => import("@/views/customer/index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW CUSTOMER", "EDIT CUSTOMER", "CREATE CUSTOMER"],
          },
        },
        {
          path: "acq-customer-list",
          name: "acqCustomerList",
          component: () => import("@/views/customer/index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW CUSTOMER", "EDIT CUSTOMER", "CREATE CUSTOMER"],
          },
        },
        {
          path: "supplierlist",
          name: "Supplierlist",
          component: () => import("@/views/customer/Supplier"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW SUPPLIER", "EDIT SUPPLIER", "CREATE SUPPLIER"],
          },
        },
        {
          path: ":id",
          name: "CustomerDetail",
          component: () => import("@/views/customer/edit/Index"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW CUSTOMER",
              "VIEW SUPPLIER",
              "EDIT CUSTOMER",
              "EDIT SUPPLIER",
            ],
          },
        },
        // {
        //   path: ':id',
        //   name: 'CustomerDetail',
        //   component: () => import('@/views/customer/CustomerDetail'),
        // }
      ],
    },
    {
      path: "/purchase",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "list",
          name: "Purchaselist",
          component: () => import("@/views/purchase-order/Index"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW PURCHASE ORDER",
              "EDIT PURCHASE ORDER",
              "CREATE PURCHASE ORDER",
            ],
          },
        },
        {
          path: "create",
          name: "CreatePurchaseOrder",
          component: () => import("@/views/purchase-order/AddPurchaseOrder"),
          meta: {
            requiresAuth: true,
            permission: ["CREATE PURCHASE ORDER"],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              path: ":id",
              name: "PurchaseOrderDetail",
              component: () =>
                import("@/views/purchase-order/PurchaseOrderDetail.vue"),
              meta: {
                requiresAuth: true,
                permission: ["VIEW PURCHASE ORDER", "EDIT PURCHASE ORDER"],
              },
            },
          ],
        },
      ],
    },
    {
      path: "/inventory",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "list",
          name: "inventorylist",
          component: () => import("@/views/inventory/Index"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW INVENTORY",
              "EDIT INVENTORY",
              "CREATE INVOICE & QUOTE",
            ],
          },
        },
        {
          path: "location",
          name: "Location",
          component: () => import("@/views/inventory/Location.vue"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW INVENTORY",
              "EDIT INVENTORY",
              "CREATE INVOICE & QUOTE",
            ],
          },
        },
        {
          path: "location-item",
          name: "Location Item",
          component: () => import("@/views/inventory/LocationItem.vue"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW INVENTORY",
              "EDIT INVENTORY",
              "CREATE INVOICE & QUOTE",
            ],
          },
        },
        {
          path: "tag-locator/:id",
          name: "TagLocator",
          component: () => import("@/views/tag-locator/Index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW INVENTORY", "EDIT INVENTORY"],
          },
        },
      ],
    },
    {
      path: "/invoice",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "list",
          name: "InvoiceList",
          component: () => import("@/views/invoice/list/Index"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW INVOICE & QUOTE",
              "EDIT INVOICE & QUOTE",
              "CREATE INVOICE & QUOTE",
            ],
          },
        },
        {
          path: "create",
          name: "CreateInvoice",
          component: () => import("@/views/invoice/create/Index"),
          meta: {
            requiresAuth: true,
            permission: ["CREATE INVOICE & QUOTE"],
          },
        },
        {
          path: "sale-report",
          name: "SaleReport",
          component: () => import("@/views/sale-report/list/Index.vue"),
          meta: {
            requiresAuth: true,
            permission: [
              "VIEW INVOICE & QUOTE",
              "EDIT INVOICE & QUOTE",
              "CREATE INVOICE & QUOTE",
            ],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              path: ":id",
              name: "InvoiceDetails",
              component: () => import("@/views/invoice/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: ["VIEW INVOICE & QUOTE", "EDIT INVOICE & QUOTE"],
              },
            },
          ],
        },
      ],
    },
    {
      path: "/picking",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "list",
          name: "PickingList",
          component: () => import("@/views/picking/dispatch-list/Index"),
          // component: () => import("@/views/invoice/list/Index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW PICKING AND DISPATCH", "EDIT INVOICE & QUOTE"],
          },
        },
      ],
    },
    {
      path: "/vehicleacquisitions",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "list",
          name: "VehicleAcquisitionsList",
          component: () => import("@/views/vehicle-acquisitions/list/Index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW VA", "EDIT VA & PICKUP", "CREATE VA"],
          },
        },
        {
          path: "stocklist",
          name: "VehicleAcquisitionsStockList",
          component: () =>
            import("@/views/vehicle-acquisitions-stock/list/Index"),
          meta: {
            requiresAuth: true,
            permission: ["VIEW VEHICLE STOCK", "EDIT VEHICLE STOCK"],
          },
        },
        // {
        //   path: "pickup",
        //   name: "VehicleAcquisitionsPickupList",
        //   component: () =>
        //     import("@/views/vehicle-acquisitions-pickup/list/Index"),
        //   meta: {
        //     requiresAuth: true,
        //     permission: ["VIEW PICKUP", "EDIT VA & PICKUP"],
        //   },
        // },
        {
          path: "createacq",
          name: "CreateVehicleAcquisitions",
          component: () => import("@/views/vehicle-acquisitions/create/Index"),
          meta: {
            requiresAuth: true,
            permission: ["CREATE VA"],
          },
        },
        {
          path: "/",
          name: "LayoutTabs",
          components: {
            default: LayoutTabs,
          },
          children: [
            {
              path: "stock/:id",
              name: "VehicleAcquisitionsStockDetails",
              component: () =>
                import("@/views/vehicle-acquisitions-stock/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: ["VIEW VEHICLE STOCK", "EDIT VEHICLE STOCK"],
              },
            },
            {
              path: ":id",
              name: "VehicleAcquisitionsDetails",
              component: () =>
                import("@/views/vehicle-acquisitions/edit/Index"),
              meta: {
                requiresAuth: true,
                permission: ["VIEW VA", "VIEW PICKUP", "EDIT VA & PICKUP"],
              },
            },
          ],
        },
      ],
    },
    {
      path: "/authentication",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "BoxedLogin",
          path: "boxedlogin",
          component: () => import("@/views/authentication/BoxedLogin"),
        },
        {
          name: "FullLogin",
          path: "fulllogin",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "BoxedRegister",
          path: "boxedregister",
          component: () => import("@/views/authentication/BoxedRegister"),
        },
        {
          name: "FullRegister",
          path: "fullregister",
          component: () => import("@/views/authentication/FullRegister"),
        },
        {
          name: "Error",
          path: "error",
          component: () => import("@/views/authentication/Error"),
        },
      ],
    },
    {
      path: "/settings",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "user",
          name: "userList",
          component: () => import("@/views/settings/user/Index"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "role",
          name: "roleList",
          component: () => import("@/views/settings/role/Index"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "role/update/:id",
          name: "UpdateRole",
          component: () => import("@/views/settings/role/update/Index"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
      ],
    },
    {
      path: "/administrator",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "connect-app-to-xero",
          name: "ConnectAppToXero",
          component: () => import("@/views/administrator/connect-xero/Index"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "catalog-management",
          name: "CatalogManagement",
          component: () =>
            import(
              "@/views/administrator/catalog-management/CatalogManagement.vue"
            ),
        },
        {
          path: "management-partname",
          name: "Part Name Management",
          component: () =>
            import("@/views/administrator/part/PartNameManagement"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "management-partno",
          name: "Part No Management",
          component: () =>
            import("@/views/administrator/part/PartNoManagement"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "management-partprice",
          name: "Part Price Management",
          component: () =>
            import("@/views/administrator/part/PartPriceManagement"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        // {
        //   path: "",
        //   name: "Part Management",
        //   component: () =>
        //     import("@/layouts/layout/LayoutCatalogManagement.vue"),
        //   meta: {
        //     requiresAuth: true,
        //     role: "SUPER ADMIN",
        //     Pa: "123",
        //     headerTabConfig: {
        //       navItems: [
        //         {
        //           title: "Part Name",
        //           name: "Part Name Management",
        //           router: "management-partname",
        //         },
        //         {
        //           title: "Part Price",
        //           name: "Part Price Management",
        //           router: "management-partprice",
        //         },
        //       ],
        //     },
        //   },
        //   children: [

        //   ],
        // },
      ],
    },
    {
      path: "/back-order-part",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "threshold-setting",
          name: "ThresholdSetting",
          component: () =>
            import(
              "@/views/back-order-part/threshold-setting/ThresholdSetting"
            ),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
        {
          path: "list",
          name: "BackOrderList",
          component: () => import("@/views/back-order-part/list/BackOrderList"),
          meta: {
            requiresAuth: true,
            role: "SUPER ADMIN",
          },
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";
import { getToken } from "./utils/auth";
import LayoutTabs from "@/layouts/layout/LayoutTabs.vue";

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start(800);
//   }
//   next();
// });
NProgress.configure({ showSpinner: false });
const whiteList = [
  "/auth/login",
  "/auth/forgot-password",
  "/auth/change-password",
]; // no redirect whitelist

router.beforeEach((to, from, next) => {
  const hasToken = getToken("ezipart-token");
  NProgress.start(800);
  if (hasToken) {
    if (whiteList.indexOf(to.path) !== -1) {
      next({ path: "/" });
      NProgress.done();
    } else {
      // Check if the route requires a role
      if (to.meta.role && !hasRole(to.meta.role)) {
        next({ path: "/", query: { noAccess: true } }); // Redirect to a no-access page or show a message
        NProgress.done();
      } else if (
        to.meta.permission &&
        !hasMultiplePermission(to.meta.permission)
      ) {
        next({ path: "/", query: { noAccess: true } }); // Redirect to a no-access page or show a message
        NProgress.done();
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/auth/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
