<template>
  <v-container fluid class="down-top-padding">
    <v-card>
      <v-card-text class="py-2 px-4">
        <v-row dense>
          <v-col cols="10">
            <h3
              class="text-subtitle-1 text-sm-h6 title blue-grey--text text--darken-2 font-weight-regular"
            >
              {{ form.stockNo }} - {{ form.vehicleNo }}
              <v-btn text color="primary" @click="toggleToAcqHandler">{{
                form.acqNo
              }}</v-btn>
              <span>{{ activeStatus }}</span>
            </h3>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-btn
              color="primary"
              :loading="isLoading"
              :disabled="isLoading"
              @click="updateStockSubmitHandler"
              v-if="hasPermission('EDIT VEHICLE STOCK')"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-tabs height="35px" color="primary" class="mt-3" light>
        <v-tab>General Information</v-tab>
        <v-tab>Summary</v-tab>
        <v-tab @click="getPurchaseDetailsTabDataHandler"
          >Purchase Details</v-tab
        >
        <!--        <v-tab>Options</v-tab>-->
        <v-tab>Stock</v-tab>
        <!-- @click="getStockHandler" -->
        <!--        <v-tab>Strip Infomation</v-tab>-->
        <v-tab>History & Events</v-tab>
        <v-tab>Notes</v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text class="py-2 px-5">
              <!-- <v-row no-gutters>
                <v-col cols="10"> </v-col>
                <v-col cols="2" class="d-flex justify-end">
                  
                </v-col>
              </v-row> -->

              <v-row dense class="">
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="makeOptions"
                    v-model="form.makeId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Make"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="colorOptions"
                    v-model="form.bodyColourId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Body Colour"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="trimColorOptions"
                    v-model="form.trimColourId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Trim"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="modelOptions"
                    v-model="form.modelId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Model"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    class="pb-1"
                    v-model="form.vin"
                    dense
                    outlined
                    label="VIN"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    class="pb-1"
                    v-model.number="form.chassis"
                    dense
                    outlined
                    label="Chassis"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="seriesOptions"
                    v-model="form.seriesId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Series"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    class="pb-1"
                    v-model="form.engineNo"
                    dense
                    outlined
                    label="Engine No"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="modelTypeOptions"
                    v-model="form.modelTypeId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Type"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="engineOptions"
                    v-model="form.engineId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Engine Type"
                    :disabled="false"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    v-model="form.locationId"
                    :loading="location.isLocationloading"
                    :items="location.locationOptions"
                    hide-no-data
                    item-text="locationCode"
                    item-value="locationID"
                    :cache-items="false"
                    dense
                    outlined
                    flat
                    label="Location"
                    @input.native="locationSearchHandler"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    outlined
                    v-model="form.regFlag"
                    :items="regFlagOptions"
                    dense
                    item-text="name"
                    item-value="id"
                    label="Un register"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    :items="regStateOptions"
                    v-model="form.regState"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Reg State"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    class="pb-1"
                    dense
                    v-model="form.lotNo"
                    outlined
                    label="Lot No"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    v-model="form.regNo"
                    dense
                    outlined
                    label="Reg No"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    outlined
                    dense
                    :items="locationGroupOptions"
                    v-model="form.locGroupId"
                    item-value="locGroupID"
                    item-text="locGroupDesc"
                    label="Sale Yard"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    :items="yearOptions"
                    v-model="form.year"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Year"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    :items="monthOptions"
                    v-model="form.month"
                    outlined
                    dense
                    item-text="name"
                    item-value="value"
                    label="Month"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    class="pb-1"
                    :items="transimissionOptions"
                    v-model="form.transId"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Transmission"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-autocomplete
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    label="Un register"
                    :disabled="true"
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" lg="2">
                  <v-text-field
                    class="pb-1"
                    v-model="form.odometer"
                    dense
                    outlined
                    label="Odometer"
                    :disabled="true"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex mt-n3">
                  <v-checkbox
                    class="pb-1"
                    v-model="form.active"
                    :true-value="1"
                    :false-value="0"
                    label="Active"
                    :disabled="true"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    class="pb-1 ml-4"
                    v-model="form.excludeFromStats"
                    :true-value="1"
                    :false-value="0"
                    label="Exclude from Vehicle Analysis"
                    :disabled="true"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <!-- <v-row class="mb-n4"> </v-row>

              <v-row class="mb-n9"> </v-row>

              <v-row class="mb-n4"> </v-row> -->

              <v-row no-gutters class="mt-2">
                <v-col cols="12">
                  <DropFile
                    ref="dropFile"
                    :stockPictures="stockPictures"
                    @send-files="receiveDataFromChild($event)"
                    @openDialog="openDialog($event)"
                    @remove-files="removedFile"
                    :allowUpload="hasPermission('EDIT VEHICLE STOCK')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <!-- <v-row dense>
              <v-col cols="12"> -->
            <v-row dense>
              <!-- <v-col cols="12" class="d-flex"> -->
              <v-col cols="12" sm="6" lg="4">
                <v-autocomplete
                  class="pb-4"
                  v-model="form.contactId"
                  :loading="supplier.isSupplierloading"
                  :items="supplier.supplierOptions"
                  item-text="company"
                  item-value="debtorId"
                  dense
                  outlined
                  @input.native="supplierSearchHandler"
                  flat
                  label="People"
                  hide-details
                ></v-autocomplete>
                <v-textarea
                  class="pb-4"
                  v-model="form.comments"
                  dense
                  outlined
                  label="Comments"
                  variant="outlined"
                  no-resize
                  rows="4"
                  row-height="15"
                  hide-details
                ></v-textarea>
                <v-autocomplete
                  class="pb-4"
                  :items="stockPurchaseTypeOptions"
                  v-model="form.purchaseTypeId"
                  outlined
                  dense
                  item-text="purchaseTypeDesc"
                  item-value="purchaseTypeID"
                  label="Purchase Type"
                  :disabled="true"
                  hide-details
                ></v-autocomplete>
                <v-autocomplete
                  class=""
                  :items="plateOptions"
                  v-model="form.platesId"
                  outlined
                  dense
                  item-text="platesDesc"
                  item-value="platesID"
                  label="Plates"
                  :disabled="true"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <v-text-field
                  class="pb-4"
                  v-model="form.licenseNo"
                  dense
                  outlined
                  label="License/LMCT No"
                  hide-details
                ></v-text-field>
                <v-text-field
                  class="pb-4"
                  v-model="form.purchase"
                  dense
                  outlined
                  label="Total Price $"
                  :disabled="true"
                  hide-details
                ></v-text-field>
                <v-text-field
                  class="pb-4"
                  v-model="form.sales"
                  dense
                  outlined
                  label="Sell Price $"
                  :disabled="true"
                  hide-details
                ></v-text-field>
                <v-autocomplete
                  class="pb-4"
                  :items="writeOffOptions"
                  v-model="form.writeOffId"
                  outlined
                  dense
                  item-text="writeOffDesc"
                  item-value="writeOffID"
                  label="Write-Off Category"
                  :disabled="true"
                  hide-details
                ></v-autocomplete>
                <VueCtkDateTimePicker
                  class=""
                  v-model="form.dateDismantled"
                  label="Date Dismantled"
                  :disabled="isDismantledDateDisabled"
                  format="YYYY-MM-DD HH:mm:ss:SSS"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4">
                <VueCtkDateTimePicker
                  class="pb-4"
                  v-model="form.acquisitionDate"
                  label="Acquisition Date"
                  format="YYYY-MM-DD HH:mm:ss"
                />
                <v-autocomplete
                  class="pb-4"
                  :items="stockOptions"
                  v-model="form.stockTypeId"
                  outlined
                  dense
                  item-text="stockTypeDesc"
                  item-value="stockTypeID"
                  label="Type"
                  :disabled="true"
                  hide-details
                ></v-autocomplete>
                <v-text-field
                  class="pb-4"
                  v-model="form.deliveredBy"
                  dense
                  outlined
                  label="Delivered By"
                  hide-details
                ></v-text-field>
                <VueCtkDateTimePicker
                  class="pb-4"
                  v-model="form.dateReceived"
                  label="Date Received"
                  format="YYYY-MM-DD HH:mm:ss:SSS"
                />
                <v-checkbox
                  class="pb-4 mt-0"
                  v-model="form.dismantled"
                  :true-value="1"
                  :false-value="0"
                  label="Dismantled"
                  hide-details
                ></v-checkbox>
              </v-col>
              <!-- </v-col> -->
            </v-row>
            <!-- </v-col>
            </v-row> -->
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-data-table
                  class="stock-purchase-details"
                  :headers="purchaseDetailTableHeader"
                  :items="purchaseDetailTableData"
                  :items-per-page="5"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!--        <v-tab-item>-->
        <!--          <v-container fluid>-->
        <!--            <v-row dense>-->
        <!--              <v-col cols="12" class="d-flex">-->
        <!--                <v-col cols="3">-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optAirConditioning"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Air Conditioning"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.opt4WheelDiscs"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="4 Wheel Discs"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optAlloyWheels"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Alloy Wheels"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.disWebSite"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Show on web site"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                </v-col>-->
        <!--                <v-col cols="3">-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optCentralLocking"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Central Locking"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optAdjustableSuspension"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Adjustable Suspension"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optTintedWindows"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Tinted Windows"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.disRestricted"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Air Restricted Conditioning"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                </v-col>-->
        <!--                <v-col cols="2">-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optLeatherInterior"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Leather Interior"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optAntiLockBrakes"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Anti Lock Brakes"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.opt4WheelSteering"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="4 Wheel Steering"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.disGlobal"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Allow global access"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                </v-col>-->
        <!--                <v-col cols="2">-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optCruiseControl"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Cruise Control"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optPowerSteering"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="PowerSteering"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optPowerWindows"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Power Windows"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                </v-col>-->
        <!--                <v-col cols="2">-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optSunRoof"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Sun Roof"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                  <v-checkbox-->
        <!--                    v-model="form.options.optAirBags"-->
        <!--                    :true-value="1"-->
        <!--                    :false-value="0"-->
        <!--                    label="Air Bags"-->
        <!--                    hide-details-->
        <!--                  ></v-checkbox>-->
        <!--                </v-col>-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-container>-->
        <!--        </v-tab-item>-->
        <v-tab-item>
          <v-container fluid>
            <StockTable
              :isLoading="isLoading"
              :tableData="stockTableData"
              @addItemHandler="addItemHandler"
              @editItemHandler="editItemHandler"
              @getItemPartPictureHandler="getItemPartPictureHandler"
              @refreshStockTable="refreshStockTable"
            />
          </v-container>
        </v-tab-item>
        <!--        <v-tab-item>-->
        <!--          <v-container fluid>-->
        <!--            <v-row class="mx-3">-->
        <!--              <v-col cols="12">-->
        <!--                <StripNotes :stripNotes.sync="form.stripNotes" />-->
        <!--              </v-col>-->
        <!--            </v-row>-->
        <!--          </v-container>-->
        <!--        </v-tab-item>-->
        <v-tab-item>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <HistoryAndEvents />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container fluid>
            <v-row class="mx-3">
              <v-col cols="12">
                <v-textarea
                  class="pb-6"
                  v-model="form.notes"
                  dense
                  outlined
                  label="Internal Notes"
                  variant="outlined"
                  rows="4"
                  row-height="15"
                  hide-details
                ></v-textarea>
                <v-textarea
                  v-model="form.notesPublic"
                  dense
                  outlined
                  label="Notes for Public"
                  variant="outlined"
                  rows="4"
                  row-height="15"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <IndicatorLoading
      :isLoading="isLoading"
      style="z-index: 99999"
    ></IndicatorLoading>
    <ConfirmDialog
      :isShow="showConfirmDialog"
      :fileName="fileName"
      @confirm="removeVehicleImage()"
      @cancel="closeDialog()"
    />
  </v-container>
</template>

<script>
import Options from "../../../mixins/options";
import VAOptions from "../../../mixins/vehicle-acquisition/options";
import Notification from "../../../mixins/notification";
import moment from "moment";
import { apiService } from "../../../services/api-service";
import { PermissionMixin } from "../../../mixins/permission";
import TabCarousel from "@/components/commonComponents/TabCarousel.vue";
import { saveLastPageToLocalStorage } from "@/utils/common";

export default {
  components: {
    TabCarousel,
    StockTable: () =>
      import(
        /* webpackChunkName: 'StockTable'*/ "../../../components/vehicleAcquisitions/StockTable.vue"
      ),
    StripNotes: () =>
      import(
        /* webpackChunkName: 'StripNotes'*/ "../../../components/vehicleAcquisitions/StripNotes.vue"
      ),
    HistoryAndEvents: () =>
      import(
        /* webpackChunkName: 'HistoryAndEvents'*/ "../../../components/vehicleAcquisitions/HistoryAndEvents.vue"
      ),
    IndicatorLoading: () =>
      import("@/components/vuetifyComponents/overlay/IndicatorLoading"),
    DropFile: () => import("../../components/DropFile.vue"),
    ConfirmDialog: () => import("../../components/ConfirmDialog.vue"),
  },
  mixins: [Options, VAOptions, Notification, PermissionMixin],
  data() {
    return {
      valid: "",
      form: {
        acquisitionDate: "",
        chassis: "",
        comments: "",
        dateDismantled: "",
        dateReceived: "",
        deliveredBy: "",
        dismantled: 0,
        engineNo: "",
        licenseNo: "",
        location: "",
        makeId: 0,
        model: "",
        modelId: 0,
        month: "",
        odometer: "",
        debtorName: "",
        purchaseTypeId: 0,
        regNo: "",
        regState: 0,
        table: [],
        transmission: "",
        vin: "",
        year: "",
        options: {},
      },
      isLoading: false,
      isDismantledDateDisabled: true,
      purchaseDetailTableHeader: [
        { value: "expenseDesc", text: "Description", sortable: false },
        { value: "supplier", text: "Supplier", sortable: false },
        { value: "amount", text: "Amount (exGST)", sortable: false },
        { value: "gst", text: "GST", sortable: false },
        { value: "expenseAmount", text: "Amt Paid (incGST)", sortable: false },
      ],
      purchaseDetailTableData: [],

      stockTableData: [],
      stockPictures: [],
      itemPartPictures: [],
      vehicleImage: File,
      isDeleteTagLocator: false,
      showConfirmDialog: false,
      fileName: "",

      tab: null,
      tabs: [],
      tabActive: {
        tabValue: "",
        tabName: "",
        item: {},
      },
      listTab: [],
      tabRef: {},
    };
  },
  provide() {
    return {
      stockForm: () => {
        return {
          makeId: this.form.makeId,
          modelId: this.form.modelId,
          seriesId: this.form.seriesId,
          modelTypeId: this.form.modelTypeId,
          modelYearId: this.form.year,
          engineId: this.form.engineId,
          transId: this.form.transId,
          dismantled: this.form.dismantled,
          vehicleId: this.form.vehicleId,
          colourId: this.form.bodyColourId,
          colourDesc: this.colorOptions.find(
            (res) => res.id === this.form.bodyColourId
          )?.name,
          locationId: this.form.locationId,
        };
      },
      makeOptions: () => this.makeOptions,
      modelOptions: () => this.modelOptions,
      seriesOptions: () => this.seriesOptions,
      modelTypeOptions: () => this.modelTypeOptions,
      yearOptions: () => this.yearOptions,
      colorOptions: () => this.colorOptions,
      engineOptions: () => this.engineOptions,
      transimissionOptions: () => this.transimissionOptions,
      addItemHandler: this.addItemHandler,
      editItemHandler: this.editItemHandler,
    };
  },
  async mounted() {
    await Promise.allSettled([
      this.fetchData(),
      this.getPicture(),
      this.getStockHandler(),
    ]);

    saveLastPageToLocalStorage(
      "vehicle-stock",
      this.form.vehicleId,
      this.form.vehicleNo
    );
  },
  methods: {
    async getPicture() {
      const result = await this.$api.inventory.getStockItemPicture({
        vehicleID: this.$route.params.id,
      });
      const { code, data } = result;
      if (code === 200) {
        this.stockPictures = data.map((res) => {
          return {
            imgUrl: res.fileUrl,
            imgName: res.fileName,
          };
        });
      }
    },

    async fetchData() {
      try {
        this.isLoading = true;
        const result = await this.$api.inventory.getStockByVehicleId({
          vehicleId: this.$route.params.id,
        });

        const { code, data } = result;
        if (code === 200) {
          this.form = {
            acqId: data.acqId,
            acqNo: data.acqNo,
            acquisitionDate: data.acquisitionDate,
            acquisitionNotes: data.acquisitionNotes,
            activeDate: data.activeDate,
            active: data.active,
            bodyColorDesc: data.bodyColorDesc,
            bodyColourId: data.bodyColourId,
            chassis: data.chassis,
            comments: data.comments,
            contactId: data.contactId,
            dateDismantled: data.dateDismantled,
            dateReceived: data.dateReceived,
            debtorId: data.debtorId,
            debtorName: data.debtorName,
            deliveredBy: data.deliveredBy,
            dismantled: data.dismantled,
            engine: data.engine,
            engineId: data.engineId,
            engineNo: data.engineNo,
            excludeFromStats: data.excludeFromStats,
            licenseNo: data.licenseNo,
            location: data.location,
            locationId: data.locationId,
            makeId: data.makeId,
            modelId: data.modelId,
            modelTypeId: data.modelTypeId,
            month: data.month,
            notes: data.notes,
            notesPublic: data.notesPublic,
            odometer: data.odometer,
            options: data.options,
            platesId: data.platesId,
            purchase: data.purchase,
            purchaseTypeId: data.purchaseTypeId,
            regNo: data.regNo,
            regState: data.regState,
            saleYard: data.saleYard,
            sales: data.sales,
            seriesId: data.seriesId,
            stockNo: data.stockNo,
            stockTypeId: data.stockTypeId,
            stripNotes: data.stripNotes,
            transId: data.transId,
            transmission: data.transmission,
            trimColourId: data.trimColourId,
            vehicleId: data.vehicleId,
            vehicleNo: data.vehicleNo,
            vin: data.vin,
            writeOffId: data.writeOffId,
            year: data.year,
            lotNo: data.lotNo,
            regFlag: data.regFlag,
            locGroupId: data.locGroupId,
          };
          this.getSuppliersOptions(data.debtorName);
          this.getLocationOptions(data.location);
          this.tabRef = {
            tabValue: this.form.vehicleId,
            tabName: this.form.stockNo + " - " + this.form.vehicleNo,
          };
          this.isLoading = false;
        }
      } catch (err) {
        console.error(err.message);
        this.showNotification("error", err.message);
      }
    },

    async getPurchaseDetailsTabDataHandler() {
      try {
        const result = await this.$api.vehicleAcquisition.getPurchaseDetail({
          vehicleId: this.$route.params.id,
        });
        console.log(result);
        const { code, data } = result;
        if (code === 200) {
          this.purchaseDetailTableData = data;
        }
      } catch (err) {
        console.error(err.message);
      }
    },

    async getStockHandler() {
      //已检测，有很多数据返回，只抽出需要用的数据
      try {
        this.isLoading = true;
        const result = await this.$api.inventory.getStockList({
          priceListID: 1,
          vehicleId: this.$route.params.id,
        });
        const { code, data } = result;
        if (code === 200) {
          this.stockTableData = data.map((res) => ({
            ...res,
            uuID: this.$uuid(),
            isChecked: false,
            // return {
            //   parts: res.parts,
            //   partId: res.partId,
            //   partNo: res.partNo,
            //   stockId: res.stockId,
            //   stockItemId: res.stockItemId,
            //   qty: res.qty,
            //   priceItemId: res.priceItemId,
            //   unitCost: res.unitCost,
            //   retail: res.retail,
            //   trade: res.trade,
            //   sold: res.sold,
            //   partCondition: res.partCondition,
            //   partConditionDesc: res.partConditionDesc,
            //   colourDesc: res.colourDesc,
            //   colourId: res.colourId,
            //   location: res.location,
            //   locationCode: res.locationCode,
            //   locationNo: res.locationNo,
            //   notes: res.notes,
            //   tagNo: res.tagNo,
            //   webEnable: res.webEnable,
            //   shareEnable: res.shareEnable,
            //   notesPublic: res.notesPublic,
            //   dismantled: res.chkDismantle,
            //   vehicleId: res.vehicleId,
            //   invoice: res.invoiceNo,
            //   invoiceId: res.invoiceId,
            //   isSold: res.isSold,
            //   isChecked: false,
            //   makeDesc: res.makeDesc,
            //   modelDesc: res.modelDesc,
            //   seriesDesc: res.seriesDesc,
            // };
          }));
        }
      } catch (err) {
        console.error(err.message);
        this.showNotification("error", err.message);
      } finally {
        this.isLoading = false;
      }
    },

    addItemHandler(value) {
      console.log("父组件1get", value);
      this.stockTableData.push(value);
    },

    editItemHandler(item) {
      this.$set(this.stockTableData, item.selectedIndex, item);
    },

    receiveDataFromChild(data) {
      this.vehicleImage = data;
    },
    removedFile() {
      this.vehicleImage = {};
    },

    async updateStockSubmitHandler() {
      const {
        acqNo,
        acquisitionDate,
        acquisitionNotes,
        active,
        bodyColourId,
        chassis,
        comments,
        contactId,
        dateDismantled,
        dateReceived,
        debtorName,
        deliveredBy,
        dismantled,
        engine,
        engineId,
        engineNo,
        excludeFromStats,
        licenseNo,
        locationId,
        makeId,
        modelId,
        modelTypeId,
        month,
        notes,
        notesPublic,
        odometer,
        options: {
          disGlobal,
          disRestricted,
          disWebSite,
          // disposalDebtorID,
          // disposalDebtorName,
          opt4WheelDiscs,
          opt4WheelSteering,
          optAdjustableSuspension,
          optAirBags,
          optAirConditioning,
          optAlloyWheels,
          optAntiLockBrakes,
          optCentralLocking,
          optCruiseControl,
          optLeatherInterior,
          optPowerSteering,
          optPowerWindows,
          optSunRoof,
          optTintedWindows,
        },
        platesId,
        purchase,
        purchaseTypeId,
        regNo,
        regState,
        saleYard,
        sales,
        seriesId,
        stockNo,
        stockTypeId,
        stripNotes,
        transId,
        transmission,
        trimColourId,
        vehicleId,
        vehicleNo,
        vin,
        writeOffId,
        year,
      } = this.form;

      const itemData = this.stockTableData.map((res) => {
        const result = {
          colourId: res.colourId,
          bodyColourId: res.colourId,
          dismantled: res.dismantled,
          locationNo: res.locationNo,
          notes: res.notes,
          notesPublic: res.notesPublic,
          partCondition: res.partCondition,
          partId: res.partId,
          partNo: res.partNo,
          priceItemId: res.priceItemId,
          qty: res.qty,
          retail: res.retail,
          shareEnable: res.shareEnable,
          stockId: res.stockId || 0,
          trade: res.trade,
          unitCost: res.unitCost,
          vehicleId: res.vehicleId,
          webEnable: res.webEnable,
        };
        return result;
      });

      const params = {
        // acqId,
        acqNo,
        acquisitionDate,
        acquisitionNotes,
        active,
        // activeDate,
        bodyColourId,
        chassis,
        comments,
        contactId,
        dateDismantled,
        dateReceived,
        // debtorId, //api 没有让传
        debtorName,
        deliveredBy,
        dismantled,
        engine,
        engineId,
        engineNo,
        items: itemData,
        excludeFromStats,
        licenseNo,
        locationId,
        makeId,
        // model, //api 没有让传
        modelId,
        modelTypeId,
        month,
        // "notes": "", //api要传 没有这个值
        notes,
        notesPublic,
        odometer,
        options: {
          disGlobal,
          disRestricted,
          disWebSite,
          // disposalDebtorID, //api要传 没有这个值
          // disposalDebtorName, //api要传 没有这个值
          opt4WheelDiscs,
          opt4WheelSteering,
          optAdjustableSuspension,
          optAirBags,
          optAirConditioning,
          optAlloyWheels,
          optAntiLockBrakes,
          optCentralLocking,
          optCruiseControl,
          optLeatherInterior,
          optPowerSteering,
          optPowerWindows,
          optSunRoof,
          optTintedWindows,
        },
        platesId,
        purchase,
        purchaseTypeId,
        regNo,
        regState,
        saleYard,
        sales,
        seriesId,
        stockNo,
        stockTypeId,
        stripNotes,
        // "tradeMeId": 0, //API让传，没有这个值
        // "tran": "", //API让传，没有这个值
        transId,
        transmission,
        trimColourId,
        vehicleId,
        vehicleNo,
        vin,
        writeOffId,
        year,
      };
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append(
          "detailSaveVo",
          new Blob([JSON.stringify(params)], {
            type: "application/json",
          })
        );

        if (this.vehicleImage.size > 0) {
          formData.append("imageList", this.vehicleImage);
        }
        this.vehicleImage = {};
        const result = await apiService.inventory.saveStock(formData);
        this.$refs.dropFile.removeFile();
        if (result.code === 200) {
          this.showNotification("success", result.msg);
          this.fetchData();
          this.getPicture();
          this.getStockHandler();
        }
      } catch (err) {
        console.error(err.message);
        this.showNotification("error", err.message);
      } finally {
        this.isLoading = false;
      }
    },

    supplierChangeHandler(value) {
      this.form.contactId = value.contactId;
      this.form.debtorId = value.debtorId;
      this.form.debtorName = value.company;
    },

    toggleToAcqHandler() {
      //已检测
      this.$router.push({
        name: "VehicleAcquisitionsDetails",
        params: { id: this.form.acqId },
      });
      // const routeUrl = this.$router.resolve({
      //   name: "VehicleAcquisitionsDetails",
      //   params: { id: this.form.acqId },
      // });
      // window.open(routeUrl.href, "_blank");
    },

    async getItemPartPictureHandler(value) {
      //已检测
      try {
        // console.log(value)
        const result = await this.$api.inventory.getStockItemPartPicture(value);
        const { code, data } = result;
        if (code === 200) {
          this.itemPartPictures = data.map((res) => {
            return {
              imgUrl: res.fileUrl,
              imgName: res.fileName,
            };
          });
          if (data.length === 0) {
            this.showNotification("error", "There is no image for this part");
          }
        }
      } catch (err) {
        this.showNotification("error", err.message);
      }
    },

    async refreshStockTable() {
      await this.getStockHandler();
    },

    openDialog(imgName) {
      this.fileName = imgName;
      this.showConfirmDialog = true;
    },
    closeDialog() {
      this.showConfirmDialog = false;
    },
    async removeVehicleImage() {
      try {
        this.isDeleteTagLocator = false;
        const result = await apiService.inventory.deleteStockImages(
          this.isDeleteTagLocator,
          this.fileName
        );
        this.getPicture();
        this.showConfirmDialog = false;
        this.showNotification("success", result.msg);
      } catch (error) {
        console.log("Remove vehicle image error:", error);
      }
    },
  },
  computed: {
    activeStatus() {
      if (!this.form.active && this.form.activeDate)
        return `${this.form.active ? "active" : "inactive"} as of ${moment(
          this.form.activeDate
        ).format("YYYY-MM-DD")}`;
      else return "";
    },
  },
  watch: {
    "form.dismantled": {
      handler(newValue) {
        this.isDismantledDateDisabled = newValue === 0 ? true : false;
      },
    },
  },
};
</script>
<style scoped lang="scss">
.down-top-padding .tabs {
  display: flex;
  column-gap: 12px;
  margin-bottom: 12px;
}

.tabs .tab-item {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #9b9fa7a6;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 12px;
  color: #ffffff99;
}

.tabs .tab-item.active {
  //border-bottom: 2px solid #f5f5f5;
  background: #1e88e5;
  color: #ffffff;
}

::v-deep
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
  > .v-input__control
  > .v-input__slot {
  min-height: 30px;
}
::v-deep .v-text-field--outlined.v-input--dense .v-label {
  top: 7px;
}

::v-deep .v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px;
}

::v-deep
  .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 5px;
}
::v-deep .stock-purchase-details .v-data-footer__select {
  flex-basis: auto;
}
::v-deep .stock-purchase-details .v-data-footer__pagination {
  display: none;
}
</style>
