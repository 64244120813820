import request from "@/utils/request";

export function getvehicleAcquisitionList(data) {
  return request({
    url: "/mgr/vehicleAcquisition/queryAcquisitionList",
    method: "post",
    data,
  });
}

export function getLocationGroupList() {
  return request({
    url: "/mgr/vehicleAcquisition/queryLocationGroupList",
    method: "post",
  });
}

export const addNotes = (data) => {
  return request({
    url: "/mgr/vehicleAcquisition/addNotes",
    method: "post",
    data,
  });
};

export function getPurchaseDetail(data) {
  return request({
    url: "/mgr/inventory/browseVehicles/purchaseDetail",
    method: "get",
    params: data,
  });
}

export function addTvehicleAcquisitionInfo(data) {
  return request({
    url: "/mgr/vehicleAcquisition/addTvehicleAcquisitionInfo",
    method: "post",
    data,
  });
}

export function getvehicleAcquisitionByAcqId(data) {
  return request({
    url: "/mgr/vehicleAcquisition/queryAcquisitionDetail",
    method: "post",
    data,
  });
}

export function updatevehicleAcquisitionInfo(data) {
  return request({
    url: "/mgr/vehicleAcquisition/updateTvehicleAcquisitionInfo",
    method: "post",
    data,
  });
}

export function addToStock(data) {
  return request({
    url: "/mgr/vehicleAcquisition/addToStock",
    method: "post",
    params: data,
  });
}

export function searchACQ(data) {
  return request({
    url: "/mgr/vehicleAcquisition/getPoNumList",
    method: "get",
    params: data,
  });
}

export function getHistoryAndEvents(data) {
  return request({
    url: "/mgr/vehicleAcquisition/queryHistoryAndEvents",
    method: "get",
    params: data,
  });
}

export function changeAcqStatus(data) {
  return request({
    url: "/mgr/vehicleAcquisition/changeAcqStatus",
    method: "get",
    params: data,
  });
}

// print
export function printAcq(id) {
  return request({
    url: `/mgr/vehicleAcquisition/previewVehicleAcquisition/${id}`,
    method: "get",
  });
}
export function printVehicleTag(id) {
  return request({
    url: `/mgr/vehicleAcquisition/previewVehicleTag/${id}`,
    method: "get",
  });
}
export function printDisposalForm(id) {
  return request({
    url: `/mgr/vehicleAcquisition/previewDisposalForm/${id}`,
    method: "get",
  });
}

//photo and signature
export function getPhotoAndSignature(id) {
  return request({
    url: `/acqImages/getPhotoAndSignature/${id}`,
    method: "get",
  });
}

//send receipt
export function sendReceipt(id, data) {
  return request({
    url: `/mgr/vehicleAcquisition/sendVehiclePurchaseReceipt/${id}`,
    method: "post",
    data: data,
  });
}

//check duplicate regNo, vin, chassis
export function checkDuplicateData(data) {
  return request({
    url: `/mgr/vehicleAcquisition/validateInput`,
    method: "post",
    data: data,
  });
}
